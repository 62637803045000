<template>
    <div class="row">
        <div class="col-md-12">
            <span class="h4 titulosg" v-if="accion"> Nueva Clase </span>
            <span class="h4 titulosg" v-if="!accion"> Editar Clase </span>
            <br>            
            <span class="text-secondary"> Registre las actividades por grado y asignatura </span>

            <form @submit.prevent="accionformulario" method="POST">
                
                <div class="row mt-3 mb-5 bg-white  ">
                <div class="col-md-9 ">
                    <div class="p-4 mb-3">

                        <div class="row mb-4">             
                            <div class="col-md-12">
                                <label for="inputPassword4" class="form-label"><strong>Grado:</strong></label>
                                <select class="form-select mb-2" name="acagradosgrupos_idacagradosgrupos" v-model="datosclase.acagradosgrupos_idacagradosgrupos" required @change="consultarasignaturasasignadas">
                                    <option value="">-</option>
                                    <option v-for="(item, index) in gruposasignados" :key="index" :value="item.grados.idacagradosgrupos">  {{item.grados.nombregradogrupo}}     </option>
                                </select>

                                <label for="inputPassword4" class="form-label"><strong>Asignatura:</strong></label>
                                <select class="form-select mb-2" name="acaasignaturas_idacaasignaturas" v-model="datosclase.acaasignaturas_idacaasignaturas" required >
                                    <option value="">-</option>
                                    <option v-for="(item, index) in asignacionacademica" :key="index" :value="item.asignaturas.idacaasignaturas">  {{item.asignaturas.descripcion}}     </option>
                                </select>

                            </div>
                            <div class="col-md-6">
                                <label for="titulo" class="form-label"> Periodo Académico: <span class="text-danger">*</span> </label>
                                <select required  class="form-select" id="acaperiodo_idperiodoacademico" name="acaperiodo_idperiodoacademico" v-model="datosclase.acaperiodo_idperiodoacademico">
                                    <option  v-for="item in periodosacademicosactivos" :key="item.idperiodoacademico" :value="item.idperiodoacademico"> Periodo {{item.numero}}  /  {{item.fechainicio}} Hasta {{item.fechafin}}  </option>
                                </select>
                            </div>                            
                        </div>  
           
                         <div class="mb-2">             
                             <label for="titulo" class="form-label">Título de la Clase: <span class="text-danger">*</span> </label>
                             <input type="text" required class="form-control" id="titulo" name="titulos" v-model="datosclase.titulos" >
                         </div>
                         
                          <label for="" class="form-label">Descripcón de la Clase</label>
                         <div id="app" class="mb-3">
                         
                             <editor
                                     v-model="datosclase.descripcion"
                                     api-key="fjq4jztxghzswoxpb6olz475m2jrssujbs1d09f2xr6r0aj3"
                                     :init="{
                                     height: 300,
                                     menubar: false,
                                     language: 'es',
                                     plugins: [
                                     'advlist autolink lists link image charmap print preview anchor',
                                     'searchreplace visualblocks code fullscreen',
                                     'insertdatetime media table paste code wordcount imagetools '
                                     ],
                                     toolbar:
                                     'undo redo | formatselect | bold italic backcolor | image link media table imagetools  visualblocks \
                                     alignleft aligncenter alignright alignjustify | \
                                     bullist numlist outdent indent | removeformat '
                                 }"                                
                                 />                   
                         </div>    
 
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <label for="fechainicio" class="form-label">Publicar Desde:</label>
                                <input required type="date" class="form-control" id="fechainicio" v-model="datosclase.fechainicio">
                              </div>
                              <div class="col-md-3">
                                <label for="fechafin" class="form-label">Hasta:</label>
                                <input required type="date" :min="datosclase.fechainicio" class="form-control" id="fechafin" v-model="datosclase.fechacierre">
                              </div>
                        </div>
             
                        <div class="row">
                            <div class="col-md-6 mb-4">
                                <label for="tipoactividad" class="form-label"><strong> Tipo de Actividad:</strong> </label>
                                <select required name="tipoactividad" id="tipoactividad" class="form-select" v-model="datosclase.tipoactividad">
                                    <option value="1">Clase </option>
                                    <option value="2">Trabajo en casa </option>
                                    <option value="3">Actividad de Recuperación </option>
                                </select>
                            </div>

                        </div>

                        <div class="row mb-4">                            
                            <div class="col-md-3">
                            <label for="estadoclase" class="form-label"><strong> Estado:</strong> </label>
                            <select required name="estadoclase" id="estadoclase" class="form-select" v-model="datosclase.estadoclase">
                                <option value="1">Activo </option>
                                <option value="2">Inactivo </option>
                            </select>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary me-2" :disabled="mostrar"> <i class="fas fa-save"></i> 
                        <div class="spinner-border spinner-border-sm" role="status" v-if="mostrar">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Guardar 
                        </button>
 

                        <button type="button" class="btn btn-light" @click="cancelar"> Cancelar </button>
                    </div>

                </div>
            </div>
        </form>

        </div>
    </div>
</template>

<script>

import { ref, onMounted, reactive } from 'vue'
import axios from 'axios'
import Editor from '@tinymce/tinymce-vue'

import { useRoute, useRouter } from 'vue-router'

export default {
    components: {
     'editor': Editor
    },
    props: ['accion','id'],
    setup(props) {
        
        const route = useRoute()
        const router = useRouter()
        
        const accion = ref(true)
        const mostrar = ref(false)
        
        const asignacionacademica = ref([])
        const periodosacademicosactivos = ref([])                
        const gruposasignados = ref([])

        const datosclase = reactive({
            idacaclase: null,
            acaperiodo_idperiodoacademico: '',
            acaasignaturas_idacaasignaturas: '',
            acagradosgrupos_idacagradosgrupos: '',
            titulos: '',
            slug: '',
            descripcion: '',
            fechainicio: '',
            fechacierre: '',
            tipoactividad: 1,
            estadoclase: 1,
        })

        //FUNCIONES
        const consultarclase = () => {

            axios.get('/api/infoclase/'+props.id).then(response => {

                datosclase.idacaclase = response.data.idacaclase
                datosclase.acaperiodo_idperiodoacademico = response.data.acaperiodo_idperiodoacademico
                datosclase.acaasignaturas_idacaasignaturas = response.data.acaasignaturas_idacaasignaturas
                datosclase.acagradosgrupos_idacagradosgrupos = response.data.acagradosgrupos_idacagradosgrupos
                datosclase.titulos = response.data.titulos
                datosclase.descripcion = response.data.descripcion
                datosclase.fechainicio = response.data.fechainicio
                datosclase.fechacierre = response.data.fechacierre
                datosclase.tipoactividad = response.data.tipoactividad
                datosclase.estadoclase = response.data.estadoclase

            })
        }
 

        const consultargruposasignados = () => {
            axios.get('/api/gradosasignados/sinid').then(response => {
                gruposasignados.value = response.data 
                 consultarasignaturasasignadas()
            })
        }

        const consultarasignaturasasignadas = () => {
            axios.get('/api/asignaturasasignadas/sinid').then(response => {
                asignacionacademica.value = response.data
            })
        }

        const periodosacademicos = () => {
            axios.get('/api/periodosacademicosactivos').then( response => {
                periodosacademicosactivos.value = response.data
            })
        }

        const accionformulario = () => {

                mostrar.value = true
                let url = ""

                if(props.accion == "nueva") { url = "/api/guardarclase" }
                else{ url = "/api/actualizarclase" }

                axios.post(url, { data: datosclase }).then(response => {
                    // console.log(response.data)
                    swal("Bien!", "Información Registrada Correctamente!", "success");
                    router.push({ path: '/clase/'+response.data })

                }).catch( error => { console.log(error) })
                
                mostrar.value = false
        }

        const cancelar = () => {
            router.go(-1)
        }

        onMounted(() => {
            consultargruposasignados()
            periodosacademicos()

            if(props.accion !== "nueva"){ 
                accion.value = false 
                consultarclase()
            } 
        })        

        return {
            accion,
            datosclase,
            asignacionacademica,
            periodosacademicosactivos,
            mostrar,
            gruposasignados,

            //Funciones
            accionformulario,
            cancelar,
            consultarasignaturasasignadas            
        }

    }
}
</script>

<style>

</style>
